@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    p {
        @apply text-base;
        @apply mb-6;
        @apply text-theme_blue;
    }

    a {
        @apply transition-colors;
    }

    .advantages .advantageWrapper:hover img {
        @apply -translate-y-3;
    }
}

input[type="range"]::-webkit-slider-thumb {

    -webkit-appearance: none;
    border-radius: 50%;
    @apply w-6 h-6 bg-theme_green;
    cursor: ew-resize;
    transition: background .3s ease-in-out;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}